import Link from 'next/link'
import Image from 'next/image'

export default function NotFound() {
    return (
        <div className="relative bg-gray-50 overflow-hidden">
            <div className="text-center">
                <h1 className="py-12 text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span className="block xl:inline">404</span>{' '}
                    <span className="block text-cyan-500 xl:inline">Page Not Found</span>
                </h1>
                <div className='py-12 m-4'>
                    <Image src="/404.svg" height={523} width={800} layout={"intrinsic"}/>
                </div>
                <div className="py-12 mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                    <div className="rounded-md shadow">
                        <Link  href="/"><a
                            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-cyan-500 hover:bg-cyan-700 md:py-4 md:text-lg md:px-10"
                        >
                            Homepage
                        </a></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}